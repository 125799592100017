import React, { useEffect } from 'react'
import { RouteObject, HashRouter, useRoutes } from 'react-router-dom'
import { Box } from '@/packages'
import { Dashboard } from '@/views/Dashboard'
import { About } from '@/views/About'
import { Product } from '@/views/Product'
import { Writing } from '@/views/Writing'
import { Team } from '@/views/Team'
import { AppMaxWidth } from '@/config'
import { LaunchNav, Footer } from '@/components'
import { useMatchBreakpoints } from '@/hooks'

export const rootRouter: RouteObject[] = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/product',
    element: <Product />
  },
  {
    path: '/writing',
    element: <Writing />
  }
  // {
  //   path: '/team',
  //   element: <Team />
  // }
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export function AppRouter() {
  // const { isMobile, isTablet } = useMatchBreakpoints()

  // useEffect(() => {
  //   window.Webflow && window.Webflow.destroy()
  //   window.Webflow && window.Webflow.ready()
  //   window.Webflow && window.Webflow.require('ix2').init()
  //   document.dispatchEvent(new Event('readystatechange'))
  // })

  return (
    <Box className="h100 ">
      <HashRouter>
        <LaunchNav />
        <Router />
      </HashRouter>
    </Box>
  )
}
